<template>
  <div class="questions">
    <div class="wrapper-content">
      <h3 class="questions__title">Частые вопросы</h3>
      <ul class="questions__list questions-list">
        <li
          v-for="({ title, desc }, index) in questions"
          :key="index"
          class="questions-list__item"
          :class="{
            'questions-list__active': opened.includes(index),
          }"
          @click="toggleQuestion(index)"
        >
          <h5 class="questions-list__title">
            {{ title }}
          </h5>
          <VueSlideUpDown :active="opened.includes(index)">
            <div class="questions-list__desc">
              <p v-for="(item, indexItem) in desc" :key="indexItem">
                {{ item }}
              </p>
            </div>
          </VueSlideUpDown>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import VueSlideUpDown from "vue-slide-up-down";

export default {
  name: "Questions",
  data() {
    return {
      questions: [
        {
          title: "Как это работает?",
          desc: [
            "Вы можете погасить займ наличными деньгами, платежом с банковской карты, банковским переводом.",
          ],
        },
        {
          title: "Как погасить займ?",
          desc: [
            "Вы можете погасить займ наличными деньгами, платежом с банковской карты, банковским переводом.",
          ],
        },
        {
          title: "Как получить деньги?",
          desc: [
            "Вы можете погасить займ наличными деньгами, платежом с банковской карты, банковским переводом.",
          ],
        },
        {
          title: "География сервиса",
          desc: [
            "Вы можете погасить займ наличными деньгами, платежом с банковской карты, банковским переводом.",
          ],
        },
      ],
      opened: [],
    };
  },
  methods: {
    toggleQuestion(index) {
      const exist = this.opened.indexOf(index);

      if (~exist) {
        this.opened.splice(exist, 1);
      } else {
        this.opened.push(index);
      }
    },
  },
  components: {
    VueSlideUpDown,
  },
};
</script>
